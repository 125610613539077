<template>
  <div class="main_content align-items-center" style="min-height:80vh;">
   
    <div class="row justify-content-center">
        <div class="col-md-12 d-lg-none d-md-none">
          <div id="example-1">
            <button class="btn-burger" @click="show = !show"> <svg xmlns="http://www.w3.org/2000/svg" width="33.575" height="29.631" viewBox="0 0 35.575 38.631" style=""><g id="Right_arrow" data-name="Right arrow" transform="translate(-12884.354 20078.389)"><path id="Path_30" data-name="Path 30" d="M1744.908,353.578l10.914,10.889-10.937,10.965" transform="translate(11159.357 -20428.078)" fill="none" stroke-linecap="round" stroke-linejoin="round" stroke-width="5.5"></path><path id="Path_31" data-name="Path 31" d="M1744.908,353.578l10.914,10.889-10.937,10.965" transform="translate(11143.357 -20428.078)" fill="none" stroke-linecap="round" stroke-linejoin="round" stroke-width="5.5"></path></g></svg>    {{btnText}}</button>
            <transition name="slide">
              <aside v-show="show">
                      <div class="text-end">
                        <button class="btn-burger" @click="show = !show">
                        X {{btnText}}
                          </button>
                      </div>
                    <div class="anotherSide">
                            <ul class="nav flex-column">
        <li class="nav-item" @click="show = !show">
          <router-link :class="
                      $route.name == 'my_reports'
                        ? 'router-link active'
                        : 'router-link'
                    " class="nav-link" :to="{ name: 'my_reports' }" aria-current="page">
            <span data-feather="home">
              <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 18 18">
                <path id="Path_113" data-name="Path 113" d="M13,21V11h8V21ZM3,13V3h8V13Zm6-2V5H5v6ZM3,21V15h8v6Zm2-2H9V17H5Zm10,0h4V13H15ZM13,3h8V9H13Zm2,2V7h4V5Z" transform="translate(-3 -3)" fill=" " />
              </svg>
            </span> My Reports
          </router-link>
        </li>
        <li class="nav-item" @click="show = !show">
          <router-link :class="
                      $route.name == 'all_user' || $route.name =='help_center'
                        ? 'router-link active'
                        : 'router-link'
                    " class="nav-link" :to="{ name: 'help_center' }" aria-current="page">
            <span data-feather="home" class="help-center stroke_icon">
               <svg id="UI-Part" xmlns="http://www.w3.org/2000/svg" width="23" height="23" viewBox="0 0 23 23">
                <g id="lifebuoy">
                  <rect id="Rectangle" width="23" height="23" fill="none" />
                  <circle id="Oval" cx="8.625" cy="8.625" r="8.625" transform="translate(2.875 2.875)" fill="#000" stroke=" " stroke-linecap="round" stroke-linejoin="round" stroke-width="1.5" />
                  <circle id="Oval-2" data-name="Oval" cx="3.594" cy="3.594" r="3.594" transform="translate(7.906 7.906)" fill="#fff" stroke=" " stroke-linecap="round" stroke-linejoin="round" stroke-width="1.5" />
                  <line id="Path" x1="3.558" y1="3.558" transform="translate(5.4 5.4)" fill="none" stroke=" " stroke-linecap="round" stroke-linejoin="round" stroke-width="1.5" />
                  <line id="Path-2" data-name="Path" y1="3.558" x2="3.558" transform="translate(14.043 5.4)" fill="none" stroke=" " stroke-linecap="round" stroke-linejoin="round" stroke-width="1.5" />
                  <line id="Path-3" data-name="Path" x2="3.558" y2="3.558" transform="translate(14.043 14.043)" fill="none" stroke=" " stroke-linecap="round" stroke-linejoin="round" stroke-width="1.5" />
                  <line id="Path-4" data-name="Path" x1="3.558" y2="3.558" transform="translate(5.4 14.043)" fill="none" stroke=" " stroke-linecap="round" stroke-linejoin="round" stroke-width="1.5" />
                </g>
              </svg>
            </span> Help Center
          </router-link>
        </li>
      </ul>
                    </div>

              </aside>
            </transition>
          </div>
        </div>
   <div class="row justify-content-between mb_54">
     
      <div class="col-md-12 col-lg-6">
        <div class="heading">
          <h2>My Reports</h2>
          <h3>Employee Dashboard</h3>
        </div>
      </div>
      <div class="col-md-12 col-lg-6 mt-md-0 mt-2">
        <div class="date_toolbar_main">
          <div class="bg-white date-toolbar">
            <div class="row">
              <div class="col-12 align-self-center">
                <div class="row">
                  <div class="col-6">
                    <div class="date">
                      <div class="heading_5">From</div>
                      <div class="text_black font_size_14 d-flex align-items-center">
                        <span
                          ><svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="17.5"
                            height="17.5"
                            viewBox="0 0 17.5 17.5"
                          >
                            <path
                              data-name="Path 131"
                              d="M7.444 6.556V3m7.111 3.556V3m-8 7.111h8.889M4.778 19h12.444A1.778 1.778 0 0 0 19 17.222V6.556a1.778 1.778 0 0 0-1.778-1.778H4.778A1.778 1.778 0 0 0 3 6.556v10.666A1.778 1.778 0 0 0 4.778 19z"
                              transform="translate(-2.25 -2.25)"
                              style="
                                fill: none;
                                stroke-linecap: round;
                                stroke-linejoin: round;
                                stroke-width: 1.5px;
                              "
                            /></svg></span
                        > <datepicker placeholder="MM DD, YYYY" v-model="start_date" :format="customFormatter" @selected="changeStartDate" :disabledDates="disabledFutureDates"></datepicker>
              
              <p class="min-date-value d-none">{{ start_date | formatDate }}</p>                      
             
                      </div>
                    </div>
                  </div>
                  <div class="col-6">
                    <div class="date todate pl_6 border-0">
                      <div class="heading_5">To</div>
                      <div class="text_black font_size_14 d-flex align-items-center">
                        <span
                          ><svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="17.5"
                            height="17.5"
                            viewBox="0 0 17.5 17.5"
                          >
                            <path
                              data-name="Path 131"
                              d="M7.444 6.556V3m7.111 3.556V3m-8 7.111h8.889M4.778 19h12.444A1.778 1.778 0 0 0 19 17.222V6.556a1.778 1.778 0 0 0-1.778-1.778H4.778A1.778 1.778 0 0 0 3 6.556v10.666A1.778 1.778 0 0 0 4.778 19z"
                              transform="translate(-2.25 -2.25)"
                              style="
                                fill: none;
                                stroke-linecap: round;
                                stroke-linejoin: round;
                                stroke-width: 1.5px;
                              "
                            /></svg></span
                        ><datepicker placeholder="MM DD, YYYY" v-model="end_date" :format="customFormatter" @selected="changeEndDate" :disabled=isDisabled :disabledDates="disabledDates"></datepicker>
                         <p class="max-date-value d-none">{{ end_date | formatDate }}</p>  
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div> 



    <div class="row">
      <div class="col-md-12">
        <div class="table_card">
          <div class="row justify-content-end align-items-center pb_40">
            <div class="col-md-12">
              <div class="align-left d-flex align-items-center justify-content-between">
                <h3 class="card_heading">Reports</h3>
              </div>
            </div>
          </div>
          <div class="table-responsive">
         
            <table class="table" id="datatable">
              <thead>
                <tr>
                  <th scope="col">
                    Date Of Assessment                    
                  </th>
                  <th scope="col">
                    Name
                   
                  </th>
                  <th scope="col">
                    Email Address                    
                  </th>
                  <!-- <th scope="col">
                    Location
                  </th> -->
                  <th scope="col">
                    Type Of Assessment                    
                  </th>
                  <th scope="col">Action</th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="(report, indx) in reports.reports" :key="indx">                  
                  <td>
                    <p class="">{{ report.assessment_date | formatDate }}</p>
                  </td>
                  <td>
                    <p>{{ first_name }}</p>
                  </td>
                  <td>
                    <p class="email_tag">
                      <a :href="'mailto:' + report.user_email">{{
                        report.user_email
                      }}</a>
                    </p>
                  </td>
                  <!-- <td>
                    <p>{{report.location ? report.location : 'Not Available'}}</p>
                  </td> -->
                  <td>
                    <p>{{report.type_of_assessment ? report.type_of_assessment : 'Not Available'}}</p>
                  </td>

                  <td>
                    <div class="edit_btn">
                      <p>
                        <router-link :to="{ name: 'view_my_reports', params: { url: report.report_id }}" target="_blank">
                         <img
                            class="edit-icon"
                            src="../../../assets/images/dashboard/View_Icon.svg"
                            alt="img"
                          />
                        </router-link >
                        <!-- <a :href="report.summary_report" target="_blank">
                          <img
                            class="edit-icon"
                            src="../../assets/images/dashboard/View_Icon.svg"
                            alt="img"
                          />
                        </a> -->
                        &nbsp;
                        <a
                          :href="'#'"
                          @click="downloadZipFile(report.summary_report)"
                        >
                          <img
                            class="edit-icon"
                            src="../../../assets/images/dashboard/download.svg"
                            alt="img"
                          />
                        </a>
                      </p>
                    </div>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
   
    

    <!-- Video Modal start Here -->
    <div
      class="modal fade"
      id="exampleModal"
      tabindex="-1"
      aria-labelledby="exampleModalLabel"
      aria-hidden="true"
    >
      <div class="modal-dialog modal-dialog-centered modal-lg">
        <div class="modal-content">
          <div class="modal-body">
            <div class="video_elements">
              <a
                href="#"
                class="modal_close"
                data-bs-dismiss="modal"
                aria-label="Close"
                >X</a>
              <iframe
                src="https://player.vimeo.com/video/327610298?h=88406977fd&amp;badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479"
                frameborder="0"
                allow="autoplay; fullscreen; picture-in-picture"
                allowfullscreen
                title="Neck Stretch"
              ></iframe>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- Video Modal End Here -->

    <div
      class="modal fade download_modal"
      id="download"
      tabindex="-1"
      aria-labelledby="downloadLabel"
      aria-hidden="true"
    >
      <div class="modal-dialog modal-dialog-centered modal-lg">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title" id="downloadLabel">
              <h3 class="heading_2">Download</h3>
            </h5>
            <button
              type="button"
              class="btn-close"
              data-bs-dismiss="modal"
              aria-label="Close"
            ></button>
          </div>
          <div class="modal-body">
            <div class="row justify-content-around">
              <div class="col-md-3">
                <div class="image_upload text-center disabled">
                  <a href="#" class="text-center">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="38.891"
                      height="38.063"
                      viewBox="0 0 38.891 38.063"
                      class="d-block"
                    >
                      <path
                        data-name="Path 143"
                        d="m4 31.047 10.336-10.336a4.508 4.508 0 0 1 6.374 0l10.337 10.336m-4.508-4.508 3.575-3.575a4.508 4.508 0 0 1 6.374 0l3.575 3.575M26.539 13.016h.023M8.508 40.063h27.047a4.508 4.508 0 0 0 4.508-4.508V8.508A4.508 4.508 0 0 0 35.555 4H8.508A4.508 4.508 0 0 0 4 8.508v27.047a4.508 4.508 0 0 0 4.508 4.508z"
                        transform="translate(-2.586 -3)"
                        style="
                          fill: none;
                          stroke: #6d6d6d;
                          stroke-linecap: round;
                          stroke-linejoin: round;
                          stroke-width: 2px;
                        "
                      />
                    </svg>
                    Download as a document
                  </a>
                </div>
              </div>
              <div class="col-md-3">
                <div class="image_upload text-center">
                  <a href="#" class="text-center">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="38.891"
                      height="38.063"
                      viewBox="0 0 38.891 38.063"
                      class="d-block"
                    >
                      <path
                        data-name="Path 143"
                        d="m4 31.047 10.336-10.336a4.508 4.508 0 0 1 6.374 0l10.337 10.336m-4.508-4.508 3.575-3.575a4.508 4.508 0 0 1 6.374 0l3.575 3.575M26.539 13.016h.023M8.508 40.063h27.047a4.508 4.508 0 0 0 4.508-4.508V8.508A4.508 4.508 0 0 0 35.555 4H8.508A4.508 4.508 0 0 0 4 8.508v27.047a4.508 4.508 0 0 0 4.508 4.508z"
                        transform="translate(-2.586 -3)"
                        style="
                          fill: none;
                          stroke-linecap: round;
                          stroke-linejoin: round;
                          stroke-width: 2px;
                        "
                      />
                    </svg>
                    Download as an image
                  </a>
                </div>
              </div>
            </div>
          </div>
          <div class="modal-footer border-top-0 mx-auto mb_50">
            <button
              type="button"
              class="btn btn_primary_light mr_30"
              data-bs-dismiss="modal"
            >
              Clear
            </button>
            <button type="button" class="btn btn_primary">Download</button>
          </div>
        </div>
      </div>
    </div>
  </div>
  </div>
</template>


<script>
import "vue-select/dist/vue-select.css";
//import 'vue2-daterange-picker/dist/vue2-daterange-picker.css'

import { getError } from "@/utils/helpers";
import AuthService from "@/services/AuthService";
import $ from "jquery";
//import DateRangePicker from 'vue2-daterange-picker'
import moment from 'moment'
import Datepicker from 'vuejs-datepicker';



export default {
  name: "airbnb_my_report",
  el: '#example-1',
  computed: {
		btnText: function(){if(this.show){  
		return ''}
		return ''}
	},
  components: {
     Datepicker
      },
  data() {
    return {
      show: false,
      reportAvailable: true,
      first_name: "",
      reports: [
      ],
      start_date:'',
      end_date:'',
      data_table:'',
      isDisabled:true, 
      disabledDates: {
        to: new Date(Date.now() - 8640000)        
      },
      disabledFutureDates: {
        from: new Date(Date.now() - 8640000)
      }
    };
  },
  destroyed() {
    this.$parent.employeeDashboard = false;
  },
  updated(){            
      if(this.data_table == ''){         
        this.data_table =  $("#datatable").DataTable({
        language: {
        searchPlaceholder: "Name, Email, Type of assessment"
    },
          aoColumnDefs: [
            {
              searchable: true, 
              targets: 0,
              bSortable: true,
              aTargets: [-1],
            },
          ],
        });
      }
   

  },
  mounted() {
    this.$parent.employeeDashboard = true;
  },
  methods: {
    getSingleUserReport() {
      var _this = this;
      AuthService.getUserReportByEmail()
        .then((response) => {         
          _this.first_name = response.data.first_name;
          _this.reports = response.data;
        })
        .catch((error) => {
          this.error = getError(error);
          const errType = Object.prototype.toString
            .call(getError(error))
            .slice(8, -1)
            .toLowerCase();
          if (errType === "string") {
            this.checkType = true;
          }
        });
    },
    downloadZipFile(url) {
      AuthService.downloadZipFile(url).then((response) => {
        var fileURL = window.URL.createObjectURL(new Blob([response.data]));
        var fileLink = document.createElement("a");
        fileLink.href = fileURL;
        fileLink.setAttribute("download", "Summary_Report.html");
        document.body.appendChild(fileLink);
        fileLink.click();
      });
    },
    updateDatePicker(){   
           
    // Extend dataTables search
      $.fn.dataTable.ext.search.push(
          function( settings, data ) {            
              var min  = $('.min-date-value').html();
              var max  =  $('.max-date-value').html();              
              var createdAt = data[0] || 0; // Our date column in the table
              if (( min == "" || max == "" )||( moment(createdAt).isSameOrAfter(min) && moment(createdAt).isSameOrBefore(max)))
              {
                  return true;
              }
              return false;
          }
      );

      this.data_table.draw(); 
    },  
    changeStartDate(){        
        this.$nextTick(() => {   
          this.isDisabled= false;
          this.disabledDates = {
            to: new Date(this.start_date - 8640000),
            from: new Date(Date.now() - 8640000)
          }          
          if(this.end_date !=''){            
            this.updateDatePicker();
          }
        });
    },
    changeEndDate(){       
        this.$nextTick(() => {
          this.updateDatePicker();     
        })
    },
    customFormatter(date) {
      return moment(date).format('MMM DD, YYYY');
    }   
  },
  

};


// function reload(){
//    if (window.location.href.substr(-2) !== "?r") {
//         window.location = window.location.href + "?r";
//     }
// }
// setTimeout(reload, 100);
</script>